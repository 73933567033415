<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
            :save_loading="loading"
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :user_type="page.model">
          <template v-slot:below_photo>
            <v-row>
              <v-col v-if="record.person && !record.person.user" class="text-center">
                <base-create-user
                  :person_id="record.person.id"
                  :user_type="page.model"
                  :emailaddress="getEmailaddress"
                  :person="record.person"
                  @refresh="load"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <base-notification
                  @refresh="load"
                  :user_id="record.person.user.id"
                  :recipient="record.person.name"
                  :notifications="record.person.user.notifications"
                  :phonenumber="record.person.mobilenumber"/>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="resetPassword" :loading="reset_password_loading" small>
                  {{ $store.getters.translate("reset_your_password") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="deleteUser" :loading="reset_delete_user_loading" small color="error">
                  {{ $store.getters.translate("delete_account") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </base-profile>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="trainings_table" :page="page" :parent_module_id="record.id" relation_name="trainings" @edit="editTraining" @change="load"/>
          <base-table-includable ref="certificates_table" :page="page" :parent_module_id="record.id" relation_name="certificates" @create="createCertificate" @edit="editCertificate" @change="load"/>
          <base-table-includable ref="companies_table" :page="page" :parent_module_id="record.id" relation_name="companies" @edit="editCompany" @change="load"/>
          <base-table-includable ref="contacts_table" :page="page" :parent_module_id="record.id" relation_name="contacts" @create="createContact" @edit="editContact" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <create-person modal_name="create_person" :module_id="record.id" action="create_contact" ref="create_person" user_type="contact" @refresh="load"/>
    <base-create-dialog modal_name="create_certificate" ref="create_certificate" :page="certificates_page" :files_required="true" @refresh="load"/>
    <base-edit-dialog modal_name="edit_certificate" ref="edit_certificate" :page="certificates_page" :files_required="true" @refresh="load"/>
    <base-create-dialog modal_name="create_note" ref="create_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
    <base-edit-dialog modal_name="edit_note" ref="edit_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseProfile from "../../components/commonComponents/BaseProfile";
import CreatePerson from "../people/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseCreateDialog from "../../components/commonComponents/BaseCreateDialog";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseEditDialog,
    BaseCreateDialog,
    BaseTableIncludable,
    BaseProfile,
    CreatePerson,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.workers,
      certificates_page: helpFunctions.pages.certificates,
      notes_page: helpFunctions.pages.notes,
      record: {},
      loading: false,
      reset_password_loading: false,
      reset_delete_user_loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      this.loading = false;
    },
    updateIncludableTables() {
      this.$refs.trainings_table.records = this.record.trainings;
      this.$refs.certificates_table.records = this.record.certificates;
      this.$refs.companies_table.records = this.record.companies;
      this.$refs.contacts_table.records = this.record.contacts;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.trainings_table.selected = [];
      this.$refs.certificates_table.selected = [];
      this.$refs.companies_table.selected = [];
      this.$refs.contacts_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    resetPassword() {
      this.reset_password_loading = true;
      if (this.record.person && this.record.person.user) {
        this.$http
          .post(this.$store.getters.appUrl + "v2/resetpassword", { email: this.record.person.user.email })
          .then(() => {
            this.reset_password_loading = false;
            this.$toasted.success(this.$store.getters.translate("password_reset_requested"));
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.reset_password_loading = false;
          });
      }
      else {
        this.$toasted.error(this.$store.getters.translate("no_user"));
      }
    },
    deleteUser() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.reset_delete_user_loading = true;
          this.record.person.delete_user = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/people/" + this.record.person.id, this.record.person)
              .then(() => {
                this.reset_delete_user_loading = false;
                this.load();
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      });
    },
    createCertificate() {
      this.$refs.create_certificate.load();
      this.$refs.create_certificate.record.worker_id = this.record.id;
    },
    editCertificate(item) {
      this.$refs.edit_certificate.load(item.id);
    },
    createNote() {
      this.$refs.create_note.load();
      this.$refs.create_note.record.parent_model = 'Worker';
      this.$refs.create_note.record.parent_model_id = this.record.id;
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    editCompany(item) {
      this.$router.push("/companies/" + item.id);
    },
    editTraining(item) {
      this.$router.push("/trainings/" + item.id);
    },
    createContact() {
      this.$refs.create_person.openModal('create_person');
    },
    editContact(item) {
      this.$router.push("/contacts/" + item.id);
    },
  },
  computed: {
    getEmailaddress() {
      if (this.record.person.emailaddresses.length > 0) {
        return this.record.person.emailaddresses[0].emailaddress;
      }
      return null;
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
